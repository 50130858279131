import * as React from "react";
import { graphql } from "gatsby";

export const Awards = ({ slice }) => {
  const awardscontentprimary = slice.primary;
  const awardscontentitems = slice.items;
  return (
    <div className="apropos-award w-auto border-t m-12 lg:m-24 pt-24 lg:pt-24">
      {awardscontentprimary && (
        <>
          <h2 className="uppercase font-heading font-semibold t-2.5 mb-14 text-left text-xl lg:text-5xl">
            {awardscontentprimary.title.text}
          </h2>
          <dl className="flex flex-wrap text-left text-base">
            {awardscontentitems &&
              awardscontentitems.map((item, i) => {
                return (
                  <React.Fragment key={`${slice.id}-${i}`}>
                    <dt className="font-black w-1/5 lg:w-1/3 mb-12 font-heading">
                      {item.year.text}
                    </dt>
                    <dd
                      className="w-4/5 lg:w-2/3 mb-12 font-light lg:text-xl"
                      dangerouslySetInnerHTML={{ __html: item.award.html }}
                    ></dd>
                  </React.Fragment>
                );
              })}
          </dl>
        </>
      )}
    </div>
  );
};

export const query = graphql`
  fragment AProposPageDataBodyPrixContent on PrismicAProposPageDataBodyPrixContent {
    id
    primary {
      title {
        text
      }
    }
    items {
      year {
        text
      }
      award {
        html
      }
    }
  }
`;
