import React from 'react'
import FestifHelmet from '../components/Helmet/FestifHelmet'
import { graphql } from 'gatsby'
import { SliceZone } from '@prismicio/react'
import { components } from '../slices'
import Wolflayout from '../components/wolflayout'
import Banner from '../components/NewTheme/Ui/Banner'
import BigButton from '../components/Ui/BigButton'

const Apropos = ({ data, location }) => {
  if (!data) return null
  const apropos = data.prismicAProposPage.data
  return (
    <Wolflayout mobileTitle={'À propos'}>
      <FestifHelmet title={data.prismicAProposPage.data.metatitle.text}
                    description={data.prismicAProposPage.data.metadescription.text}
                    keywords={data.prismicAProposPage.data.metakeywords.text}
                    image={data.prismicAProposPage.data.og_img.url}
                    href={location.href} />
      <Banner title="À propos" />
      <div className="apropos w-auto flex flex-wrap pb-12 text-white">
        <div className="w-auto mt-12 lg:mt-24 mx-12 lg:mx-24">
          <div className="flex flex-row flex-wrap lg:flex-nowrap">
            <div className="apropos-col-left lg:mr-20 px-4 flex flex-col basis-full text-base font-gaksans leading-none">
              {apropos &&
                apropos.column_left.map((leftColumn) => {
                  return (
                    <React.Fragment key={leftColumn.title.text}>
                      <h2 className="font-semibold text-xl lg:text-2xl mt-2.5 mb-6 font-body">
                        {leftColumn.title.text}
                      </h2>
                      <div
                          dangerouslySetInnerHTML={{
                            __html: leftColumn.content.html
                          }}
                      ></div>
                    </React.Fragment>
                  )
                })}
            </div>
            <div className="apropos-col-right px-4 flex flex-col basis-full text-lg lg:text-base leading-tight">
              {apropos &&
                apropos.column_right.map((rightColumn) => {
                  return (
                    <React.Fragment key={rightColumn.title.text}>
                      <h2 className="font-semibold text-xl lg:text-2xl mt-2.5 mb-6 font-body">
                        {rightColumn.title.text}
                      </h2>
                      <div
                          dangerouslySetInnerHTML={{
                            __html: rightColumn.content.html
                          }}
                      ></div>
                    </React.Fragment>
                  )
                })}
            </div>
          </div>

        <div className="!pt-12"><p><BigButton text={'Réserve notre scène!'} href={'/location-scene'} target="_self"/></p></div>

        </div>
        <SliceZone slices={apropos.body} components={components} />
      </div>
    </Wolflayout>
  )
}

export const query = graphql`
  query {
    prismicAProposPage {
      data {
        metadescription {
          text
        }
        metakeywords {
          text
        }
        metatitle {
          text
        }
        og_img {
          url
        }
        column_left {
          title {
            text
          }
          content {
            html
          }
        }
        column_right {
          title {
            text
          }
          content {
            html
          }
        }
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...AProposPageDataBodyPrixContent
        }
      }
    }
  }
`

export default Apropos
