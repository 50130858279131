import * as React from "react";
import { graphql } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../styles/swiper-custom.css";

SwiperCore.use([Autoplay, Navigation, Pagination]);

export const Carousel = ({ slice }) => {
  return (
    <Swiper
      slidesPerView={1}
      loop={true}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
      speed={700}
      navigation={{
        prevEl: ".swiper-button-prev",
        nextEl: ".swiper-button-next",
      }}
      pagination={{
        el: ".swiper-pagination",
        type: "bullets",
      }}
    >
      {slice.items.map((carouselItem) => {
        const image = getImage(carouselItem.image);

        return (
          <SwiperSlide key={carouselItem.image.alt}>
            <GatsbyImage
              className="w-full aspect-video"
              image={image}
              alt={carouselItem.image.alt}
            />
          </SwiperSlide>
        );
      })}
      <div className="swiper-pagination"></div>
      <div className="swiper-button-prev"></div>
      <div className="swiper-button-next"></div>
    </Swiper>
  );
};

export const query = graphql`
  fragment HomepageDataBodyCarousel on PrismicHomepageDataBodyCarousel {
    items {
      image {
        alt
        gatsbyImageData(width: 1080)
      }
      link {
        url
      }
    }
  }
`;
