import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export const Sponsors = ({ slice }) => {
  const platinumSponsors = slice.items.filter(
    (item) => {
      return item?.sponsors?.document?.data?.section === 'Platine'
    }
  )
  return (
    <section className="bg-white pt-4">
      <div className="container flex justify-center">
        <div className="row flex w-full mb-4 flex-wrap justify-center text-center">
          {platinumSponsors.map((sponsorsItem, i) => {
            const image = getImage(
              sponsorsItem.sponsors.document.data.main_image
            )
            return (
              <a
                key={`${sponsorsItem.sponsors.document.data.sponsor_url.url}-${i}`}
                href={sponsorsItem.sponsors.document.data.sponsor_url.url}
                className="lg:mb-2 mx-4 w-1/8 lg:w-[13%] self-center"
                target="_blank"
                rel="noreferrer"
              >
                <GatsbyImage
                  className="max-w-[300px] !max-h-[100px]"
                  image={image}
                  alt={
                    sponsorsItem.sponsors.document.data.main_image.alt
                      ? sponsorsItem.sponsors.document.data.main_image.alt
                      : ''
                  }
                />
              </a>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodySponsors on PrismicHomepageDataBodySponsors {
    items {
      sponsors {
        document {
          ... on PrismicSponsors {
            data {
              main_image {
                alt
                gatsbyImageData(width: 100)
              }
              sponsor_url {
                url
              }
              section
            }
          }
        }
      }
    }
  }
`
