import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export const SponsorsHeader = ({ slice }) => {
  return (
    <section className="bg-white pt-[3rem]">
      <div className="container flex justify-center sm:m-auto">
        <div className="row flex flex-wrap justify-center">
          {slice.items.map((sponsorsHeaderItem) => {
            const image = getImage(
              sponsorsHeaderItem.sponsors.document.data.main_image
            )
            return (
              <a
                key={sponsorsHeaderItem.sponsors.document.data.sponsor_url.url}
                href={sponsorsHeaderItem.sponsors.document.data.sponsor_url.url}
                className="mb-2 mx-[25%] w-auto md:w-[16%] md:mx-4 self-center"
                target="_blank"
                rel="noreferrer"
              >
                <GatsbyImage
                  className="w-full"
                  image={image}
                  alt={sponsorsHeaderItem.sponsors.document.data.main_image.alt}
                />
              </a>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodySponsorsHeader on PrismicHomepageDataBodySponsorsHeader {
    items {
      sponsors {
        document {
          ... on PrismicSponsors {
            data {
              main_image {
                alt
                gatsbyImageData(width: 400)
              }
              sponsor_url {
                url
              }
            }
          }
        }
      }
    }
  }
`
