import { Carousel } from './Carousel'
import { Sponsors } from './Sponsors'
import { SponsorsHeader } from './SponsorsHeader'
import { Awards } from './Awards'

export const components = {
    carousel: Carousel,
    sponsors: Sponsors,
    'sponsors-header': SponsorsHeader,
    prix_content: Awards,
}